import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import cn from 'classnames'

import BillerUtils from '../utils/BillerUtils'

const Step1Modal = ({ visible, onClose, biller, referenceNumber, loading, error, onActionClick }) => {
  Modal.setAppElement('#__next');
  const [refNum, setRefNum] = useState(referenceNumber || '')
  const [refNaming, setRefNaming] = useState('')
  const [pattern, setPattern] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState('');
  const [complete, setComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (biller) {
      setRefNaming(biller.metadata.reference_naming);
      setPattern(new RegExp(biller.validation_pattern));
    }
    setRefNum(referenceNumber);
    setIsLoading(loading);
  }, [referenceNumber, biller, loading])

  const onRefNumChanged = (e) => {
    const value = e.target.value;
    setRefNum(value);
    if (pattern.test(value)) {
      if (biller.code === 'BPLAC') {
        //console.log('biller is BPLAC')
        const valid = BillerUtils.isBplacValid(value);
        setComplete(valid);
        if (valid) {
          setErrorMessage(undefined);
        } else {
          setErrorMessage('Your reference number is not valid.');
        }
      } else if (biller.code === 'PAL') {
        //console.log('biller is PAL')
        const valid = BillerUtils.isPalValid(value);
        setComplete(valid);
        if (valid) {
          setErrorMessage(undefined);
        } else {
          setErrorMessage('Your reference number is not valid.');
        }
      } else if (biller.code === '1MANULIFEA001') {
        //console.log('biller is MAMTC')
        const valid = BillerUtils.isMamtcValid(value);
        setComplete(valid);
        if (valid) {
          setErrorMessage(undefined);
        } else {
          setErrorMessage('Your reference number is not valid.');
        }
      } else if (biller.code === '1TOYFINSVC001') {
        //console.log('biller is TFSPH')
        const valid = BillerUtils.isTfsValid(value);
        setComplete(valid);
        if (valid) {
          setErrorMessage(undefined);
        } else {
          setErrorMessage('Your reference number is not valid.');
        }
      } else if (biller.code === '1PIONELIFE001') {
        //console.log('biller is PIONEERLIFE')
        const valid = BillerUtils.isPioneerLifeValid(value);
        setComplete(valid);
        if (valid) {
          setErrorMessage(undefined);
        } else {
          setErrorMessage('Your reference number is not valid.');
        }
      } else if (biller.code === '1NORKISTRA001') {
        //console.log('biller is NORKIS')
        const valid = BillerUtils.isNorkisValid(value);
        setComplete(valid);
        if (valid) {
          setErrorMessage(undefined);
        } else {
          setErrorMessage('Your reference number is not valid.');
        }
      } else if (biller.code === '1SMDEVTCOR001') {
        //console.log('biller is SMDC')
        const valid = BillerUtils.isSmdcValid(value);
        setComplete(valid);
        if (valid) {
          setErrorMessage(undefined);
        } else {
          setErrorMessage('Your reference number is not valid.');
        }
      } else if (biller.code === '1FORTLIFIN002') {
        //console.log('biller is Fortune Life')
        const valid = BillerUtils.isFlanValid(value);
        setComplete(valid);
        if (valid) {
          setErrorMessage(undefined);
        } else {
          setErrorMessage('Your reference number is not valid.');
        }
      }else if(biller.code === 'PHILAMLIFE'){
        const valid = BillerUtils.isAIAValid(value);
        setComplete(valid);
        if (valid) {
          setErrorMessage(undefined);
        } else {
          setErrorMessage('Your reference number is not valid.');
        }
      }else if(biller.code === '1PRULIFEIN001'){
        const valid = BillerUtils.isPrulifeValid(value);
        setComplete(valid);
        if (valid) {
          setErrorMessage(undefined);
        } else {
          setErrorMessage('Your reference number is not valid.');
        }
      } else {
        setComplete(true);
        setErrorMessage(undefined);
      }
    } else {
      setErrorMessage('Your reference number is not valid.');
      setComplete(false);
    }
  }

  const onNextClick = (e) => {
    e.preventDefault();
    setIsLoading(true);
    onActionClick({ referenceNumber: refNum });
  }

  const onModalClose = () => {
    onClose();
    setRefNum('');
    setErrorMessage(undefined);
    setIsLoading(false);
    setPattern(undefined);
    setComplete(false);
  }

  return (
    <Modal
      isOpen={visible}
      className="centered relative w-auto items-center sm:max-w-md sm:mx-auto m-2 my-6 outline-none"
      overlayClassName="fixed inset-0 bg-cool-gray-500 bg-opacity-75 overflow-x-hidden overflow-y-auto"
      onRequestClose={() => onModalClose()}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      closeTimeoutMS={500}
    >
      <div className="w-full bg-white bg-clip-padding rounded-lg shadow-xl p-6 sm:p-10 outline-none relative">
        <button className="h-auto w-auto p-2 bg-transparent outline-none focus:outline-none absolute top-1 right-1 text-gray-400 hover:text-gray-600 transition-opacity duration-300 ease-in" onClick={() => onModalClose()}>
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </button>
        
        <div className="text-center">
          <img className="h-20 w-20 sm:h-28 sm:w-28 rounded-full border border-gray-200 shadow-sm overflow-hidden mx-auto" src={biller !== undefined ? biller.image : ''} alt={biller !== undefined ? biller.name : ''} />
          <div className="mt-3">
            <p className="leading-5 text-gray-700">
              You are enrolling to&nbsp;
              <span className="font-bold">{biller !== undefined ? biller.name : ''}</span>
            </p>
          </div>
          <div className="mt-8">
            <div className="mb-6 text-left">
              <label htmlFor="reference" className="block text-sm leading-5 pb-2 text-gray-600">Your reference number</label>
              <div className="relative rounded-md shadow-sm">
                <input id="reference" className={cn("modal-input py-2 px-3 form-input block w-full rounded sm:text-sm sm:leading-5 border border-gray-200 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50", { 'border-danger': !!errorMessage || !!error })} placeholder="" value={refNum} onChange={(e) => onRefNumChanged(e)}  />
              </div>
              <p className={cn("mt-1 text-xs", { 'text-danger': !!errorMessage, 'text-cool-gray-400': !errorMessage })}>
                {
                  !errorMessage && !refNaming &&
                  `An account or reference number from ${biller !== undefined ? biller.name : ''}`
                }
                {
                  !!errorMessage &&
                  `${errorMessage}`
                }
              </p>
              <p className={cn("mt-1 text-xs", { 'text-danger': !!errorMessage, 'text-cool-gray-400': !errorMessage })}>
              {!!refNaming && `${refNaming}`}
              </p> 
            </div>
          </div>
        </div>
        
        <div className="mt-10 sm:mt-16">
          <p className="w-full text-sm text-danger font-medium text-center mb-1">{error}</p>
          <span className="flex w-full rounded-md shadow-sm">
            <button disabled={!complete || isLoading} onClick={(e) => onNextClick(e)} type="button" className={cn("inline-flex items-center justify-center w-full rounded-md border border-transparent px-6 py-3 bg-brand-500 text-base leading-6 font-medium text-white shadow-sm focus:outline-none focus:border-brand-700 focus:shadow-outline-red transition ease-in-out duration-150", { 'opacity-50': !complete || isLoading, 'cursor-not-allowed': !complete || isLoading, 'hover:bg-brand-700': complete || !isLoading})}>
              { isLoading && <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              }
              { isLoading ? 'Contacting your bank' : 'Next' }
            </button>
          </span>
        </div>
      </div>
    </Modal>
  );
}

export default Step1Modal;